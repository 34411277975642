

import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { IoMdCloseCircle } from "react-icons/io";

const StyledModal = styled(Modal)`
    .modal-dialog {
        max-width: 900px;
        width: auto;
    }

    @media (max-width: 1200px){
        .modal-dialog {
            max-width: 600px;
            width: auto;
        }
    }

    @media (max-width: 768px){
        .modal-dialog {
            max-width: 350px;
            width: auto;
            margin-right: auto;
            margin-left: auto;
        }
    }
`;

const ModalWrapper = styled.div`
    position: relative;

    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px){
        width: 600px;
    }

    @media (max-width: 768px){
        width: 350px;
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const CloseWrapper = styled.div`
    position: absolute;
    right:0; 
    top: 0;
`;

export interface PreviewImageProps {
    image: string | null;
    open: boolean;
    onClose?: () => void;
}

export function PreviewImage(props: PreviewImageProps) {
    return (
        <StyledModal show={props.open} onHide={props.onClose}>
            <ModalWrapper>
                <CloseWrapper onClick={props.onClose}>
                    <IoMdCloseCircle />
                </CloseWrapper>
                <Image src={props.image!} />
            </ModalWrapper>
        </StyledModal >
    );
}

