// src/components/ImageUploader.tsx
import React, { useState } from 'react';

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import { storage } from '../config/firebase';
import theme from '../constants/theme';

const UploadButton = styled.button`
  background-color: ${theme.colors.main};;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

`;

interface ImageUploaderProps {
  name: string;
  container: string;
  onUploadComplete: (image: string, url: string) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ name, container, onUploadComplete }) => {
  const [image, setImage] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [url, setUrl] = useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    if (e.target.files[0].size > 3 * 1024 * 1024) return;
    setImage(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!image) return;

    setUploading(true);
    const extension = image.name.substring(image.name.lastIndexOf('.') + 1)
    const imageRef = ref(storage, `${container}/${name}.${extension}`);
    try {
      await uploadBytes(imageRef, image);
      const downloadURL = await getDownloadURL(imageRef);
      onUploadComplete(`${container}/${name}.${extension}`, downloadURL);
      setUrl(downloadURL);
    } catch (error) {
      console.error("Error uploading image: ", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} />
      {url ?
        <img src={url} alt="upladed" />
        : <UploadButton onClick={handleUpload} disabled={uploading}>
          {uploading ? 'Pujant...' : 'Pujar archiu'}
        </UploadButton>}
    </div>
  );
};

export default ImageUploader;
