
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import Checkbox from 'react-simple-checkbox';
import { RoleEnum } from '../../../constants/enums';
import { UserContext } from '../../../context/user-provider';
import { ConfigApi } from '../../../api/config';
import { IIniciConfig } from '../../../types/Config';
import theme from '../../../constants/theme';
import { StartSchoolList } from './list';
import Box from '../../box';
import News from '../inici/news/news'


const Content = styled.div``;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 24px 0;
    gap: 8px; 
    > div {
        top:0;
    }
    > label {
        margin-bottom: initial;
    }
`;

const FormItemWrapper = styled.div<{ direction: string }>`
    display: flex; 
    align-items: center;
    justify-content: start;
    gap: 8px;
    flex-direction: ${props => props.direction};
    margin-bottom:12px;
`;

const PromoText = styled(Form.Control)`
    width: 400px;

    &::placeholder{
        font-style: italic;
    }
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

const StartSchool = styled.div`
    margin: 24px 0;
`;

const PromoAlert = styled.div`
    padding: 16px 0;
    width: 100%;

    div{
        width: 100%;
        textarea{
            width: 100%;
        }
    }
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
`;


export function Inici() {
    const user = useContext(UserContext);
    const [inici, setInici] = useState<IIniciConfig | null>(null);

    useEffect(() => {
        ConfigApi.GetById("inici")
            .then((x: IIniciConfig) => { setInici(x) })
            .catch(x => console.log(x));
    }, []);

    const handlePreinscripcioPromo = () => {
        setInici(prevState => prevState ? { ...prevState, Preinscripcio: !prevState.Preinscripcio } : null)
        ConfigApi.UpdatePreinscripcio("inici", { Preinscripcio: !inici?.Preinscripcio })
            .catch(x => console.log(x));
    }

    const handleStartSchool = () => {
        setInici(prevState => prevState ? { ...prevState, StartSchool: !prevState.StartSchool } : null)
        ConfigApi.UpdatePreinscripcio("inici", { StartSchool: !inici?.StartSchool })
            .catch(x => console.log(x));
    }

    const handleBooks = () => {
        setInici(prevState => prevState ? { ...prevState, Books: !prevState.Books } : null)
        ConfigApi.UpdatePreinscripcio("inici", { Books: !inici?.Books })
            .catch(x => console.log(x));
    }

    const handlePromoText = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PromoText: text.length === 0 ? null : text } : null)
    }

    const handlePromoAlert = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PromoAlert: text.length === 0 ? null : text } : null)
    }

    const handlePromoAlertDescription = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PromoAlertDescription: text.length === 0 ? null : text } : null)
    }

    const handlePromoAlertLink = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PromoAlertLink: text.length === 0 ? null : text } : null)
    }

    const hanlePromoSave = () => {
        ConfigApi.UpdatePreinscripcio("inici", { PromoText: inici?.PromoText })
            .catch(x => console.log(x));
    }

    const handlePortesObertesESO = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PortesObertesESO: text.length === 0 ? null : text } : null);
    }

    const handlePortesObertesESOSave = () => {
        ConfigApi.UpdatePreinscripcio("inici", { PortesObertesESO: inici?.PortesObertesESO })
            .catch(x => console.log(x));
    }

    const handlePortesObertesBatxillerat = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PortesObertesBatxillerat: text.length === 0 ? null : text } : null);
    }


    const handlePortesObertesBatxilleratSave = (text: string) => {
        ConfigApi.UpdatePreinscripcio("inici", { PortesObertesBatxillerat: inici?.PortesObertesBatxillerat })
            .catch(x => console.log(x));
    }

    const handlePortesObertesCFGM = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PortesObertesCFGM: text.length === 0 ? null : text } : null);
    }

    const handlePortesObertesCFGMSave = (text: string) => {
        ConfigApi.UpdatePreinscripcio("inici", { PortesObertesCFGM: inici?.PortesObertesCFGM })
            .catch(x => console.log(x));
    }

    const handlePortesObertesCFGS = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PortesObertesCFGS: text.length === 0 ? null : text } : null);
    }

    const handlePortesObertesCFGSSave = (text: string) => {
        ConfigApi.UpdatePreinscripcio("inici", { PortesObertesCFGS: inici?.PortesObertesCFGS })
            .catch(x => console.log(x));
    }

    const hanlePromoAlertSave = () => {
        ConfigApi.UpdatePreinscripcio("inici", {
            PromoAlert: inici?.PromoAlert,
            PromoAlertDescription: inici?.PromoAlertDescription,
            PromoAlertLink: inici?.PromoAlertLink
        })
            .catch(x => console.log(x));
    }

    return (<Content>

        <Box backgroundColor="#fff" padding="2px 24px" margin="0 0 12px" >
            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                <CheckboxWrapper>
                    <Checkbox
                        size={3}
                        checked={inici?.Preinscripcio}
                        onChange={() => handlePreinscripcioPromo()}>
                    </Checkbox>
                    <Form.Label>Preinscripció i matrícula</Form.Label>
                </CheckboxWrapper>
            }
        </Box>
        <Box backgroundColor="#fff" padding="2px 24px" margin="0 0 12px" >
            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <CheckboxWrapper>
                <Checkbox
                    size={3}
                    checked={inici?.Books}
                    onChange={() => handleBooks()}>
                </Checkbox>
                <Form.Label>Llibres de text</Form.Label>
            </CheckboxWrapper>}
        </Box>
        <Box backgroundColor="#fff" padding="15px 24px" margin="0 0 12px" >
            <Title>Banner superior</Title>
            <FormItemWrapper direction="row">
                <PromoText
                    placeholder="Text"
                    disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                    as="textarea"
                    rows={1}
                    value={inici?.PromoText ?? ''}
                    onChange={e => handlePromoText(e.target.value)} />
                <ButtonStyled onClick={hanlePromoSave}>
                    Desar Banner
                </ButtonStyled>
            </FormItemWrapper>
        </Box>
        <Box backgroundColor="#fff" padding="15px 24px" margin="0 0 12px" >
            <Title>Noticies</Title>
            <FormItemWrapper direction="row">
                <News />
            </FormItemWrapper>
        </Box>
        <Box backgroundColor="#fff" padding="2px 24px" margin="0 0 12px" >
            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                <PromoAlert>
                    <Title>Avís important</Title>
                    <FormItemWrapper direction="column">
                        <Form.Group>
                            <PromoText
                                placeholder="Títol"
                                disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                                as="textarea"
                                rows={1}
                                value={inici?.PromoAlert ?? ''}
                                onChange={e => handlePromoAlert(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <PromoText
                                placeholder="Descripció"
                                disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                                as="textarea"
                                rows={1}
                                value={inici?.PromoAlertDescription ?? ''}
                                onChange={e => handlePromoAlertDescription(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <PromoText
                                placeholder="Link"
                                disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                                as="textarea"
                                rows={1}
                                value={inici?.PromoAlertLink ?? ''}
                                onChange={e => handlePromoAlertLink(e.target.value)} />
                            <ButtonStyled onClick={hanlePromoAlertSave}>
                                Desar
                            </ButtonStyled>
                        </Form.Group>
                    </FormItemWrapper>
                </PromoAlert>
            }
        </Box>
        <Box backgroundColor="#fff" padding="2px 24px" margin="0 0 12px" >
            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                <PromoAlert>
                    <Title>Portes obertes</Title>
                    <FormItemWrapper direction="row">
                        <PromoText
                            placeholder="ESO"
                            disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                            as="textarea"
                            rows={1}
                            value={inici?.PortesObertesESO ?? ''}
                            onChange={e => handlePortesObertesESO(e.target.value)} />
                        <ButtonStyled onClick={handlePortesObertesESOSave}>
                            Desar
                        </ButtonStyled>
                    </FormItemWrapper>
                    <FormItemWrapper direction="row" >
                        <PromoText
                            placeholder="Batxillerat"
                            disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                            as="textarea"
                            rows={1}
                            value={inici?.PortesObertesBatxillerat ?? ''}
                            onChange={e => handlePortesObertesBatxillerat(e.target.value)} />
                        <ButtonStyled onClick={handlePortesObertesBatxilleratSave}>
                            Desar
                        </ButtonStyled>
                    </FormItemWrapper>
                    <FormItemWrapper direction="row" >
                        <PromoText
                            placeholder="CFGM"
                            disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                            as="textarea"
                            rows={1}
                            value={inici?.PortesObertesCFGM ?? ''}
                            onChange={e => handlePortesObertesCFGM(e.target.value)} />
                        <ButtonStyled onClick={handlePortesObertesCFGMSave}>
                            Desar
                        </ButtonStyled>
                    </FormItemWrapper>
                    <FormItemWrapper direction="row" >
                        <PromoText
                            placeholder="CFGS"
                            disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                            as="textarea"
                            rows={1}
                            value={inici?.PortesObertesCFGS ?? ''}
                            onChange={e => handlePortesObertesCFGS(e.target.value)} />
                        <ButtonStyled onClick={handlePortesObertesCFGSSave}>
                            Desar
                        </ButtonStyled>
                    </FormItemWrapper>
                </PromoAlert>
            }
        </Box>
        <Box backgroundColor="#fff" padding="2px 24px" margin="0 0 12px" >
            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                <StartSchool>
                    <Title>Inici curs</Title>
                    <CheckboxWrapper>
                        <Checkbox
                            size={3}
                            checked={inici?.StartSchool}
                            onChange={() => handleStartSchool()}>
                        </Checkbox>
                        <Form.Label>Actiu</Form.Label>
                    </CheckboxWrapper>
                    <StartSchoolList />
                </StartSchool>
            }
        </Box>

    </Content >);
}

