import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { v4 as uuidv4 } from 'uuid';
import { INews } from '../../../../types/News';
import theme from '../../../../constants/theme';
import { UserContext } from '../../../../context/user-provider';
import { NewsListApi } from '../../../../api/news';
import { RoleEnum } from '../../../../constants/enums';
import ImageUploader from '../../../imageUploader';
import Checkbox from 'react-simple-checkbox';

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 24px 0;
    gap: 8px; 
    > div {
        top:0;
    }
    > label {
        margin-bottom: initial;
    }
`;

const Content = styled.div`
    padding: 24px;
`;

const FormWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
    gap: 9px;
    padding-bottom: 24px;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

const DatePickerStyled = styled(DatePicker)`
    padding: .375rem .75rem;
    border: 1px solid lightgrey;
    border-radius: .375rem;
    width: 100%;

    > input{
        width: 100%;
    }
`;

const Label = styled.label`
    font-weight: bold;
    font-size: 14px;
    display: block;
`;

const FormItemWrapper = styled.div``;

const Image = styled.img`
    width: 100px;
    height: 100px;

`;

export interface NewsAddProps {
    onCreate: () => void;
    onClose: () => void;
    open: boolean;
    _new: INews | null;
}


export function NewsModalAdd(props: NewsAddProps) {
    const [order, setOrder] = useState<number>(props._new?.order ?? 0);
    const [image, setImage] = useState<string | undefined | null>(props._new?.image);
    const [imageUrl, setImageUrl] = useState<string | undefined | null>(props._new?.imageUrl);
    const [description, setDescription] = useState<string | undefined | null>(props._new?.description);
    const [title, setTitle] = useState<string | undefined | null>(props._new?.title);
    const [link, setLink] = useState<string | undefined | null>(props._new?.link);
    const [date, setDate] = useState<Date | undefined | null>(props._new?.date && new Date((props._new?.date as any).seconds * 1000));
    const [active, setActive] = useState<boolean>(props._new?.active ?? false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const user = useContext(UserContext);

    useEffect(() => {
        if (!success) return;
        let timer = setTimeout(() => setSuccess(false), 2000);
        return () => { clearTimeout(timer); };
    }, [success]);

    useEffect(() => {
        if (!error) return;
        let timer = setTimeout(() => setError(false), 2000);
        return () => { clearTimeout(timer); };
    }, [error]);

    const handleSave = async () => {
        if (!title || !description || !image) {
            alert('Obligatori títol, descripció i image');
            return;
        }

        const pre: INews = {
            id: props._new?.id ?? uuidv4(),
            title: title ?? '',
            description: description ?? '',
            link: link ?? '',
            image: image ?? '',
            imageUrl: imageUrl ?? '',
            order: order,
            date: date,
            active: active,
            creationAt: new Date()
        };

        if (props._new?.id !== null) {
            NewsListApi.Update(pre)
                .then((cred: any) => {
                    setSuccess(true);
                    props.onCreate();
                })
                .catch((error) => {
                    console.log(error);
                    setError(error);
                });
            return;
        }

        NewsListApi.Create(pre)
            .then((cred: any) => {
                setSuccess(true);
                props.onCreate()
            })
            .catch((error) => {
                console.log(error);
                setError(error);
            });
    };

    return (
        <Modal show={props.open} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Nou</Modal.Title>
            </Modal.Header>
            <Content>
                <FormWrapper>
                    <FormItemWrapper>
                        <Label>Image* (max 3mb)</Label>
                        {imageUrl
                            ? <Image src={imageUrl} alt="image" />
                            : <ImageUploader
                                name={props._new?.id ?? uuidv4()}
                                container="news"
                                onUploadComplete={(image, url) => {
                                    setImage(image);
                                    setImageUrl(url)
                                }} />
                        }
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Títol*</Label>
                        <Form.Control
                            disabled={user?.role === RoleEnum.secretaria}
                            as="textarea"
                            rows={1}
                            value={title ?? ''}
                            onChange={e => setTitle(e.target.value)} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Text*</Label>
                        <Form.Control
                            disabled={user?.role === RoleEnum.secretaria}
                            as="textarea"
                            rows={2}
                            value={description ?? ''}
                            onChange={e => setDescription(e.target.value)} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Link</Label>
                        <Form.Control
                            disabled={user?.role === RoleEnum.secretaria}
                            as="textarea"
                            rows={1}
                            value={link ?? ''}
                            onChange={e => setLink(e.target.value)} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Ordre</Label>
                        <Form.Control
                            type="number"
                            disabled={user?.role === RoleEnum.secretaria}
                            value={order ?? 0}
                            onChange={e => setOrder(parseInt(e.target.value))} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Data</Label>
                        <DatePickerStyled
                            disabled={user?.role === RoleEnum.secretaria}
                            width={100}
                            selected={date}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setDate(date)}
                        />
                    </FormItemWrapper>
                    <CheckboxWrapper>
                        <Checkbox
                            size={3}
                            checked={active}
                            onChange={() => setActive(!active)}>
                        </Checkbox>
                        <Form.Label>Visible</Form.Label>
                    </CheckboxWrapper>
                </FormWrapper>
                <Modal.Footer>
                    <ButtonStyled onClick={handleSave}>
                        Desar
                    </ButtonStyled>
                </Modal.Footer>
                {error && <Alert variant="danger">Error al desar el registre</Alert>}
                {success && <Alert variant="success">S’ha modificat correctament el registre</Alert>}
            </Content>
        </Modal >
    );
}

