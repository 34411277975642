import { arrayRemove, arrayUnion, doc, getDoc, getFirestore, updateDoc, } from "firebase/firestore";
import { firestore } from "../config/firebase";

import { INews } from "../types/News";
import { deleteObject, getStorage, ref } from "firebase/storage";


export namespace NewsListApi {

    const table = 'news';
    const document = 'list';
    const db = getFirestore();
    const storage = getStorage();

    export const Create = async (start: INews) => {
        const docRef = doc(db, table, document);
        await updateDoc(docRef, { list: arrayUnion(start) });
    }

    export const Delete = async (start: INews) => {
        const docRef = doc(db, table, document);
        await updateDoc(docRef, { list: arrayRemove(start) });
    }

    export const DeleteImage = async (path: string) => {
        const fileRef = ref(storage, path);
        await deleteObject(fileRef);
    }

    export const Update = async (_new: INews) => {
        const docRef = doc(firestore, table, document);
        const result = await getDoc(docRef);
        const data = result.data() as any;
        const found = (data.list as INews[]).find(x => x.id === _new.id);
        if (!found) return null;
        found.date = _new.date;
        found.order = _new.order;
        found.link = _new.link;
        found.active = _new.active;
        found.title = _new.title;
        found.description = _new.description;
        found.creationAt = _new.creationAt;
        found.imageUrl = _new.imageUrl;
        await updateDoc(docRef, { list: data.list });
    }

    export const GetById = async (id: string): Promise<INews[]> => {
        const result = await getDoc(doc(firestore, table, id));
        return result.data() as INews[];
    }
}