import { useContext, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { ItinerariEnum, RoleEnum } from '../../../constants/enums';
import { UserContext } from '../../../context/user-provider';
import theme from '../../../constants/theme';

import { IStartSchool } from '../../../types/StartSchool';
import { StartSchoolListApi } from '../../../api/start-school-list';
import { StartSchoolAdd } from './add';
import { DateCustomFormat } from '../../../service/date';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
`;

const EmptyStage = styled.div``;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
    width: fit-content;
`;

const TitleWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledTh = styled.th<{ width?: number }>`
  width: ${(props) => `${props.width}%` || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const StyledTd = styled.td<{ width?: number }>`
  width: ${(props) => `${props.width}%` || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed; // Asegura que la tabla use anchos fijos
`;


export function StartSchoolList() {
    const user = useContext(UserContext);
    const [starts, setStarts] = useState<IStartSchool[] | null>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [start, setStart] = useState<IStartSchool | null>(null);
    const [nou, setNou] = useState<boolean>(false);

    useEffect(() => {
        StartSchoolListApi.GetById("list")
            .then((x: any) => setStarts(x.list as IStartSchool[]))
            .catch(x => console.log(x))
            .finally(() => setRefresh(false));
        // eslint-disable-next-line
    }, [refresh]);


    const handlerRemove = (start: IStartSchool) => {
        if (!window.confirm('Estàs segur que vols eliminar el registre?')) return;
        StartSchoolListApi.Delete(start)
            .then(x => setRefresh(!refresh))
            .catch(x => console.log(x));
    }

    const handlerClose = () => {
        setNou(false);
        setStart(null);
    }

    const handleCreate = () => {
        setRefresh(true);
    }

    const handleNew = () => {
        setNou(true);
        setStart({
            id: null,
            Itinerari: ItinerariEnum.eso,
            Level: null,
            DateStudent: null,
            DateFamily: null
        });
    }

    const handleEdit = (value: IStartSchool) => {
        setNou(true);
        setStart(value);
    }

    return (
        <Content>
            <TitleWrapper>
                {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <ButtonStyled onClick={handleNew}>
                    Nou
                </ButtonStyled>}
            </TitleWrapper>
            {(!starts || starts.length === 0) && <EmptyStage>0 elements trobats</EmptyStage>}
            {starts && starts.length > 0 && <StyledTable
                striped
                bordered
                size="100%"
                hover>
                <thead>
                    <tr>
                        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <StyledTh width={10} />}
                        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <StyledTh width={10} />}
                        <StyledTh width={5}>Estudis</StyledTh>
                        <StyledTh width={20}>Nivell</StyledTh>
                        <StyledTh width={25}>Rebuda alumnat</StyledTh>
                        <StyledTh width={25}>Reunió familia</StyledTh>
                    </tr>
                </thead>
                <tbody>
                    {starts && starts.length > 0 && starts
                        ?.sort((a, b) => new Date(a.DateStudent!).getTime() - new Date(b.DateStudent!).getTime())
                        .map(x =>
                            <tr key={x.id}>
                                {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                                    <StyledTd width={10}>
                                        <ButtonStyled color="secondary" onClick={() => handlerRemove(x)}>
                                            Esborra
                                        </ButtonStyled>
                                    </StyledTd>
                                }
                                {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                                    <StyledTd width={10}>
                                        <ButtonStyled color="secondary" onClick={() => handleEdit(x)}>
                                            Edita
                                        </ButtonStyled>
                                    </StyledTd>
                                }
                                <StyledTd width={5}>{x.Itinerari?.toString()}</StyledTd>
                                <StyledTd width={20}>{x.Level}</StyledTd>
                                <StyledTd width={20}>{DateCustomFormat(x.DateStudent!, "#dayWeek #day de #month, #hour:#minutsh", "a concretar")}</StyledTd>
                                <StyledTd width={20}>{DateCustomFormat(x.DateFamily!, "#dayWeek #day de #month, #hour:#minutsh", "a concretar")} </StyledTd>
                            </tr>
                        )}
                </tbody>
            </StyledTable>}
            {nou && <StartSchoolAdd
                start={start}
                onClose={handlerClose}
                onCreate={handleCreate}
                open={nou}
            />}
        </Content>);
}

