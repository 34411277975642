
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import theme from '../../../constants/theme';
import { IBooks } from '../../../types/Books';
import { StudentsFamilyApi } from '../../../api/students-family';
import { ICalendar } from '../../../types/StudentFamily';
import Box from '../../box';

const Content = styled.div`
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
`;


const FormItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const FormWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
    gap: 9px;
    padding-bottom: 24px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 14px;
    display: block;
    width: 150px;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

function Students() {

    const [books, setBooks] = useState<IBooks | null>(null);
    const [calendar, setCalendar] = useState<ICalendar | null>(null);

    useEffect(() => {
        StudentsFamilyApi.ListBooks()
            .then((x: IBooks) => { setBooks(x) })
            .catch(x => console.log(x));
        StudentsFamilyApi.ListCalendar()
            .then((x: ICalendar) => { setCalendar(x) })
            .catch(x => console.log(x));
    }, []);

    const handleESO1 = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, ESO1: value } : null);
    }
    const handleESO2 = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, ESO2: value } : null);
    }
    const handleESO3 = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, ESO3: value } : null);
    }
    const handleESO4 = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, ESO4: value } : null);
    }
    const handleBatx1 = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, Batxillerat1: value } : null);
    }
    const handleBatxillerat2 = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, Batxillerat2: value } : null);
    }
    const handleAdministratiu = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, Administratiu: value } : null);
    }
    const handleAutomocio = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, Automocio: value } : null);
    }
    const handleElectricitat = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, Electricitat: value } : null);
    }

    const handleMecanica = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, Mecanica: value } : null);
    }
    const handleQuimica = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, Quimica: value } : null);
    }
    const handleSanitaria = (value: string) => {
        setBooks(prevState => prevState ? { ...prevState, Sanitaria: value } : null);
    }
    const handleCalendar = (value: string) => {
        setCalendar(prevState => prevState ? { ...prevState, url: value } : null);
    }

    const handleUpdate = (update: any) => {
        StudentsFamilyApi.Update('books', update)
            .catch(x => console.log(x));
    }

    const handleUpdateCalendar = (update: any) => {
        StudentsFamilyApi.Update('calendar', update)
            .catch(x => console.log(x));
    }

    return (<Content>
        <Box background-color="#fff" padding="14px" margin="0 0 24px">
            <Title>Calendari</Title>
            <FormWrapper>
                <FormItemWrapper>
                    <Label>Url</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={calendar?.url ?? ''}
                        onChange={e => handleCalendar(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdateCalendar({ url: calendar?.url })}>Desar</ButtonStyled>
                </FormItemWrapper>
            </FormWrapper>
        </Box>
        <Box background-color="#fff" padding="14px" margin="0 0 24px">
            <Title>Llibres</Title>
            <FormWrapper>
                <FormItemWrapper>
                    <Label>ESO 1</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.ESO1 ?? ''}
                        onChange={e => handleESO1(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ ESO1: books?.ESO1 })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>ESO 2</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.ESO2 ?? ''}
                        onChange={e => handleESO2(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ ESO2: books?.ESO2 })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>ESO 3</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.ESO3 ?? ''}
                        onChange={e => handleESO3(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ ESO3: books?.ESO3 })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>ESO 4</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.ESO4 ?? ''}
                        onChange={e => handleESO4(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ ESO4: books?.ESO4 })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>Batx 1</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.Batxillerat1 ?? ''}
                        onChange={e => handleBatx1(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ Batxillerat1: books?.Batxillerat1 })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>Batx 2</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.Batxillerat2 ?? ''}
                        onChange={e => handleBatxillerat2(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ Batxillerat2: books?.Batxillerat2 })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>Administratiu</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.Administratiu ?? ''}
                        onChange={e => handleAdministratiu(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ Administratiu: books?.Administratiu })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>Automoció</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.Automocio ?? ''}
                        onChange={e => handleAutomocio(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ Automocio: books?.Automocio })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>Electricitat i electrònica</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.Electricitat ?? ''}
                        onChange={e => handleElectricitat(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ Electricitat: books?.Electricitat })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>Mecànica</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.Mecanica ?? ''}
                        onChange={e => handleMecanica(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ Mecanica: books?.Mecanica })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>Química</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.Quimica ?? ''}
                        onChange={e => handleQuimica(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ Quimica: books?.Quimica })}>Desar</ButtonStyled>
                </FormItemWrapper>
                <FormItemWrapper>
                    <Label>Sanitat</Label>
                    <Form.Control
                        as="textarea"
                        rows={1}
                        value={books?.Sanitaria ?? ''}
                        onChange={e => handleSanitaria(e.target.value)} />
                    <ButtonStyled onClick={() => handleUpdate({ Sanitaria: books?.Sanitaria })}>Desar</ButtonStyled>
                </FormItemWrapper>
            </FormWrapper>
        </Box>
    </Content>);
}

export default Students;
