import styled from "styled-components";
import Wrapper from "../wrapper";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

const Content = styled.div`
`;

const ContentWrapper = styled.div`
`;

const Title = styled.h2`
  text-align: center;
  font-weight: bold; 
  color: #343a40;
  margin-bottom: 24px;

  @media(max-width: 1024px){
    font-size: 28px;
    line-height: 28px;
  }

  @media(max-width: 600px){
    font-size: 22px;
    line-height: 22px;
    margin: 12px 0 24px;
  }
`;



const Sections = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
 
  @media(max-width: 600px){
    gap: 12px;
  } 
`;

const Section = styled.div`
  width: calc(50% - 12px); /* Ajuste por el gap */
  background-color: #fff;
  display: flex; 
  flex-direction: row;
  gap: 12px;
  padding: 24px;
  border-radius: 12px;

  @media(max-width: 930px){
    width: 100%;
  }

  @media (max-width: 600px){
    padding: 16px;
  }

`;

const SectionText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionImage = styled.div`


`;

const ImageCover = styled.img`
  //position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  object-fit: cover;
  z-index: 1;
  border-radius: 50%;

    @media(max-width: 630px){
      width: 60px;
      height: 60px;
    }
`;

const SectionTitle = styled.h3`
  font-weight: bold;
  text-align: left;

  @media(max-width: 600px){
    font-size: 18px;
  }
`;

const SectionDescription = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;

    @media(max-width: 600px){
      font-size: 14px;
    }
`;

const More = styled.div`
  display: flex;
  justify-content: end;
  color: #c82d2d;
  margin: 0 4px;
  cursor:pointer;
  
  @media(max-width: 600px){
    font-size: 14px;
  }
`;

function Education() {
  const navigate = useNavigate();
  return (
    <Content>
      <Wrapper>
        <ContentWrapper>
          <Title>Oferta Educativa</Title>
          <Sections>
            <Section onClick={() => navigate(ROUTES.OfertaEducativaESO)}>
              <SectionImage>
                <ImageCover src={require('../../assets/img/eso.png')} alt="eso" />
              </SectionImage>
              <SectionText>
                <SectionTitle>ESO</SectionTitle>
                <SectionDescription>
                  L'ESO és una etapa d'ensenyament obligatori, estructurada en quatre cursos escolars que pretén la formació bàsica per tal de garantir a tot l'alumnat una bona orientació i preparació per al seu futur
                </SectionDescription>
                <More>veure més...</More>
              </SectionText>
            </Section>
            <Section onClick={() => navigate(ROUTES.OfertaEducativaBatxillerat)}>
              <SectionImage>
                <ImageCover src={require('../../assets/img/batxillerat.png')} alt="batxillerat" />
              </SectionImage>
              <SectionText>
                <SectionTitle>Batxillerat</SectionTitle>
                <SectionDescription>
                  El batxillerat és l'etapa que es cursa normalment entre els 16 i els 18 anys, tot i que és oberta a persones de qualsevol edat, i forma part de l'educació secundària postobligatòria. És un cicle format per dos cursos acadèmics de 30 hores lectives setmanals
                </SectionDescription>
                <More>veure més...</More>
              </SectionText>
            </Section>
            <Section onClick={() => navigate(ROUTES.OfertaEducativaCFGM)}>
              <SectionImage>
                <ImageCover src={require('../../assets/img/fp.png')} alt="fp" />
              </SectionImage>
              <SectionText>
                <SectionTitle>CFGM</SectionTitle>
                <SectionDescription>
                  La formació professional capaci ta per a l'exercici qualificat de diverses professions i proporciona la formació necessària per adquirir en competències professionals i els coneixements propis de cada sector,
                </SectionDescription>
                <More>veure més...</More>
              </SectionText>
            </Section>
            <Section onClick={() => navigate(ROUTES.OfertaEducativaCFGS)}>
              <SectionImage>
                <ImageCover src={require('../../assets/img/cfgs.png')} alt="fp" />
              </SectionImage>
              <SectionText>
                <SectionTitle>CFGS</SectionTitle>
                <SectionDescription>
                  La formació professional capacita per a l'exercici qualificat de diverses professions i proporciona la formació necessària per adquirir en competències professionals i els coneixements propis de cada sector,
                </SectionDescription>
                <More>
                  <More>veure més...</More>
                </More>
              </SectionText>
            </Section>
          </Sections>
        </ContentWrapper>
      </Wrapper>
    </Content>
  );
}

export default Education;