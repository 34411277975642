
import styled from "styled-components";
import theme from "../constants/theme";

export const Content = styled.div<{ backgroundColor: string | undefined, margin?: string }>`
  width: 100%;
  display:flex;
  box-shadow: ${theme.colors.shadow} 5px 6px 10px 0px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : theme.colors.white};
  border-radius: 12px;
  margin: ${props => props.margin || '0'};
`;

export const ChildrenWrapper = styled.div<{ padding: string | undefined }>`
  padding: ${props => props.padding ? props.padding : "30px"};
  width: 100%; 
  @media (max-width: 768px) { padding: 24px; }
  @media (max-width: 600px) { padding: 16px; }
  @media (max-width: 375px) { padding: 14px }
`;

export interface WrapperProps {
  children: React.ReactNode;
  backgroundColor?: string;
  padding?: string;
  margin?: string;
}

export default function Box(props: WrapperProps) {
  return (
    <Content backgroundColor={props.backgroundColor} margin={props.margin} >
      <ChildrenWrapper padding={props.padding}>{props.children}</ChildrenWrapper>
    </Content>
  );
}
