
import React from 'react';
import { NewsList } from './list';


const News: React.FC = () => {
  return (
    <NewsList />
  );
};

export default News;
