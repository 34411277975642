import { useContext, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { NewsListApi } from '../../../../api/news';
import { INews } from '../../../../types/News';
import { RoleEnum } from '../../../../constants/enums';
import theme from '../../../../constants/theme';
import { UserContext } from '../../../../context/user-provider';
import { NewsModalAdd } from './add';
import { BiCheck } from 'react-icons/bi';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
`;

const EmptyStage = styled.div``;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
    width: fit-content;
`;

const StyledTh = styled.th<{ width?: number }>`
  width: ${(props) => `${props.width}%` || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const StyledTd = styled.td<{ width?: number }>`
  width: ${(props) => `${props.width}%` || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed; // Asegura que la tabla use anchos fijos
`;


export function NewsList() {
    const [news, setNews] = useState<INews[] | null>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const user = useContext(UserContext);
    const [_new, set_New] = useState<INews | null>(null);
    const [nou, setNou] = useState<boolean>(false);

    useEffect(() => {
        NewsListApi.GetById("list")
            .then((x: any) => {
                let sorted = x.list
                    .sort((a, b) => a.order - b.order);
                setNews(sorted);
            })
            .catch(x => console.log(x))
            .finally(() => setRefresh(false));
        // eslint-disable-next-line
    }, [refresh]);

    const handlerRemove = (_news: INews) => {
        if (!window.confirm('Estàs segur que vols eliminar el registre?')) return;

        NewsListApi.DeleteImage(_news.image)
            .catch(x => console.log(x));
        NewsListApi.Delete(_news)
            .then(x => setRefresh(!refresh))
            .catch(x => console.log(x));
    }

    const handlerClose = () => {
        setNou(false);
        set_New(null);
    }

    const handleCreate = () => {
        setRefresh(true);
        setNou(false);
    }

    const handleNew = () => {
        setNou(true);
        set_New({
            id: null,
            title: '',
            description: '',
            link: '',
            image: '',
            imageUrl: '',
            order: 0,
            date: null,
            active: true,
            creationAt: new Date()
        });
    }

    const handleEdit = (value: INews) => {
        set_New(value);
        setNou(true);
    }

    return (
        <Content>
            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <ButtonStyled onClick={handleNew}>
                Nou
            </ButtonStyled>}

            {(!news || news.length === 0) && <EmptyStage>0 elements trobats</EmptyStage>}
            {news && news.length > 0 && <StyledTable
                striped
                bordered
                size="100%"
                hover>
                <thead>
                    <tr>
                        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <StyledTh width={5} />}
                        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <StyledTh width={5} />}
                        <StyledTh width={5}>Ordre</StyledTh>
                        <StyledTh width={5}>Visible</StyledTh>
                        <StyledTh width={20}>Títol</StyledTh>
                    </tr>
                </thead>
                <tbody>
                    {news && news.length > 0 && news.map(x =>
                        <tr key={x.id}>
                            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                                <StyledTd width={5}>
                                    <ButtonStyled color="secondary" onClick={() => handlerRemove(x)}>
                                        Esborra
                                    </ButtonStyled>
                                </StyledTd>
                            }
                            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                                <StyledTd width={5}>
                                    <ButtonStyled onClick={() => handleEdit(x)}>
                                        Edita
                                    </ButtonStyled>
                                </StyledTd>
                            }
                            <StyledTd width={5}>{x.order}</StyledTd>
                            <StyledTd width={5}>{x.active ? <BiCheck /> : null}</StyledTd>
                            <StyledTd width={20}>{x.title}</StyledTd>
                        </tr>
                    )}
                </tbody>
            </StyledTable>}
            {nou && <NewsModalAdd
                _new={_new}
                onClose={handlerClose}
                onCreate={handleCreate}
                open={nou}
            />}
        </Content>);
}

